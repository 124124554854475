html, body, #root, .studio_v2, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    background: #22222C;
    color: #ffffff;
    overflow: hidden;
}

body > iframe {
    display: none;
}

p {
    margin: unset;
}

.dialog .primary_button,
.side_panel .primary_button,
.app .primary_button {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    border-radius: 5px;
    font-family: "Quantico", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    padding: 10px 15px;
    text-transform: capitalize;
}

.dialog .primary_button:disabled,
.side_panel .primary_button:disabled,
.app .primary_button:disabled {
    color: #000000;
    opacity: 0.5;
}

.dialog .secondary_button,
.side_panel .secondary_button,
.app .secondary_button {
    background: linear-gradient(123.89deg, #FAC01E 8.56%, #FC762A 86.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 5px;
    font-family: "Quantico", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    padding: 10px 15px;
    text-transform: capitalize;
}

.dialog .secondary_button:disabled,
.side_panel .secondary_button:disabled,
.app .secondary_button:disabled {
    color: #000000;
    opacity: 0.5;
}

/* scroll bar */
.scroll_bar {
    overflow: auto;
}

html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 13px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #757575;
}
